import React from "react";
import {
  Box,
  Container,
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Page from "../../component/Page";
import "./faq.scss";

const FaqPageView = () => {
  const [expanded, setExpanded] = React.useState(false);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  return (
    <Page title="Faq | QXP">
      <Container maxWidth="lg">
        <Box className="privacy-policy-container">
          <Box className="terms-conditions-container">
            <Typography variant="h1">FAQ</Typography>
            <Box className="faq-wrap">
              <Accordion
                expanded={expanded === "panel1"}
                onChange={handleChange("panel1")}
              >
                <AccordionSummary
                  aria-controls="panel1d-content"
                  id="panel1d-header"
                  expandIcon={<ExpandMoreIcon />}
                >
                  <Typography>
                    How to request for a service from a service provider?
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography>
                    <b>You can follow the below steps to book a service:</b>
                    <ul>
                      <li>
                        Search for the service you need on the Home Page.Type in
                        the service type manually or ask Siri like: “Siri, ask
                        qxp to find a painter.”
                      </li>
                      <li>Nearest Service Provider will receive a request.</li>
                      <li>
                        Service Provider will call you momentarily and discuss
                        your request
                      </li>
                      <li>
                        You need to accept their service by Accepting Request
                      </li>
                      <li>Your request is confirmed.</li>
                      <li>
                        Once you confirmed the provider ,will send a status
                        update of their route to your address.
                      </li>
                      <li>
                        Finally, you will receive a final notification upon
                        arrival of your selected provider.
                      </li>
                    </ul>
                  </Typography>
                </AccordionDetails>
              </Accordion>
              <Accordion
                expanded={expanded === "panel2"}
                onChange={handleChange("panel2")}
              >
                <AccordionSummary
                  aria-controls="panel12-content"
                  id="panel2d-header"
                  expandIcon={<ExpandMoreIcon />}
                >
                  <Typography>How to pay for services?</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography>
                    <ul>
                      <li>
                        QXP will help you to find the nearest service provider.
                      </li>
                      <li>
                        QXP is a free service to any customer all around the
                        world.
                      </li>
                      <li>
                        You will only pay the selected service provider you
                        selected
                      </li>
                    </ul>
                  </Typography>
                </AccordionDetails>
              </Accordion>
              <Accordion
                expanded={expanded === "panel3"}
                onChange={handleChange("panel3")}
              >
                <AccordionSummary
                  aria-controls="panel3d-content"
                  id="panel3d-header"
                  expandIcon={<ExpandMoreIcon />}
                >
                  <Typography>
                    How to know if my request is confirmed?
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography>
                    <ul>
                      <li>
                        Once your service request is confirmed,you will get push
                        notification in your mobile device, plus SMS & Email.
                      </li>
                      <li>
                        You can check the request status on the Service Request
                        Page in the QXP app.
                      </li>
                    </ul>
                  </Typography>
                </AccordionDetails>
              </Accordion>
              <Accordion
                expanded={expanded === "panel4"}
                onChange={handleChange("panel4")}
              >
                <AccordionSummary
                  aria-controls="panel4d-content"
                  id="panel4d-header"
                  expandIcon={<ExpandMoreIcon />}
                >
                  <Typography>How to cancel a service request?</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography>
                    <b>
                      You can follow the below steps to cancel a service
                      request:
                    </b>
                    <ul>
                      <li>Go to Service Request Page on the QXP app</li>
                      <li>Click on Cancel.</li>
                      <li>Select Reason and Submit.</li>
                    </ul>
                  </Typography>
                </AccordionDetails>
              </Accordion>
              <Accordion
                expanded={expanded === "panel5"}
                onChange={handleChange("panel5")}
              >
                <AccordionSummary
                  aria-controls="panel5d-content"
                  id="panel5d-header"
                  expandIcon={<ExpandMoreIcon />}
                >
                  <Typography>How to reschedule a service request?</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography>
                    <b>
                      You can follow the below steps to reschedule a service
                      request:
                    </b>
                    <ul>
                      <li>Go to Service Request Page on QXP app</li>
                      <li>Click on Reschedule.</li>
                      <li>Select Reason and Submit.</li>
                    </ul>
                  </Typography>
                </AccordionDetails>
              </Accordion>
              <Accordion
                expanded={expanded === "panel6"}
                onChange={handleChange("panel6")}
              >
                <AccordionSummary
                  aria-controls="panel6d-content"
                  id="panel6d-header"
                  expandIcon={<ExpandMoreIcon />}
                >
                  <Typography>How to check request history?</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography>
                    <b>You can follow the below steps to check history</b>
                    <ul>
                      <li>Open Menu Drawer from Left Side.</li>
                      <li>Click on History.</li>
                      <li>
                        You can search with the request number and also the
                        provider’s name.
                      </li>
                    </ul>
                  </Typography>
                </AccordionDetails>
              </Accordion>
              <Accordion
                expanded={expanded === "panel7"}
                onChange={handleChange("panel7")}
              >
                <AccordionSummary
                  aria-controls="panel7d-content"
                  id="panel7d-header"
                  expandIcon={<ExpandMoreIcon />}
                >
                  <Typography>
                    How can I contact the provider before they arrive?
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography>
                    <b>
                      You can follow the below steps to contact professional
                    </b>
                    <ul>
                      <li>Open Menu Drawer from Left Side.</li>
                      <li>Click on Service Requests.</li>
                      <li>Go to Detail Page, select the service request</li>
                      <li>
                        Now you can contact them via SMS or call them directly.
                      </li>
                    </ul>
                  </Typography>
                </AccordionDetails>
              </Accordion>
              <Accordion
                expanded={expanded === "panel8"}
                onChange={handleChange("panel8")}
              >
                <AccordionSummary
                  aria-controls="panel8d-content"
                  id="panel8d-header"
                  expandIcon={<ExpandMoreIcon />}
                >
                  <Typography>
                    How can I rate and review a service provider?
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography>
                    <b>You can follow the below steps to rate and review</b>
                    <ul>
                      <li>Open Menu Drawer from Left Side.</li>
                      <li>Click on Service Requests.</li>
                      <li>Go to Detail Page</li>
                      <li>
                        Once Service Request Completed, Rate and Review Option
                        will be visible
                      </li>
                      <li>Submit your Ratings and Review.</li>
                    </ul>
                  </Typography>
                </AccordionDetails>
              </Accordion>
              <Accordion
                expanded={expanded === "panel9"}
                onChange={handleChange("panel9")}
              >
                <AccordionSummary
                  aria-controls="panel9d-content"
                  id="panel9d-header"
                  expandIcon={<ExpandMoreIcon />}
                >
                  <Typography>
                    Which Documents are required to validate as Service
                    Provider?
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography>
                    <b>
                      List of documents are valid to become service provider
                    </b>
                    <ul>
                      <li>Driving Licence</li>
                      <li>Applicable insurance docs.</li>
                      <li>Company’s Licence</li>
                    </ul>
                  </Typography>
                </AccordionDetails>
              </Accordion>
              <Accordion
                expanded={expanded === "panel10"}
                onChange={handleChange("panel10")}
              >
                <AccordionSummary
                  aria-controls="panel10d-content"
                  id="panel10d-header"
                  expandIcon={<ExpandMoreIcon />}
                >
                  <Typography>
                    How will I know that My documents are verified?
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography>
                    <ul>
                      <li>
                        Our Team will review documents and inform once verified.
                      </li>
                      <li>Usually this process can take upto 24 hours.</li>
                    </ul>
                  </Typography>
                </AccordionDetails>
              </Accordion>
            </Box>
          </Box>
        </Box>
      </Container>
    </Page>
  );
};

export default FaqPageView;
