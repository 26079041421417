import { AUTH_DATA } from "../constants";

const initialState = {}; //authData: { user_id: 1 }

export default function AuthDatas(state = initialState, action) {
  switch (action.type) {
    case AUTH_DATA:
      return { ...state, authData: action.data };

    default:
      return state;
  }
}
