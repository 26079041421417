import React, { useEffect, useState } from "react";
import { styled } from "@mui/material/styles";
import Page from "../../component/Page";
import { useDispatch } from "react-redux";
import { authData } from "../../helpers/stores/actions/auth";
import { setApiMessage } from "../../helpers/notification";
import { fetchFromStorage, saveToStorage } from "../../helpers/storage";
import { Button, Container, Box, Typography, Grid } from "@mui/material";
import "./Verification.scss";
import axios from "axios";
import { ReactComponent as UploadIcon } from "../../assets/images/icons/fileUpload-icon.svg";
import { URLS } from "../../helpers/url";
import pdfPlaceholderImg from "../../assets/images/pdf-placeholder.png";
import { Navigate, useNavigate, NavLink } from "react-router-dom";

const Input = styled("input")({
  display: "none",
});

const VerificationPageView = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const auth = fetchFromStorage("authData");
  const [license, setLicense] = useState(null);
  const [registration, setRegistration] = useState(null);
  const [licenseImgpreview, setLicenseImgpreview] = useState([]);
  const [certificateImgpreview, setCertificateImgpreview] = useState([]);
  const [pdfName, setPdfName] = useState("");
  const [licenseSuccess, setLicenseSuccess] = useState(false);
  const [registrationSuccess, setRegistrationSuccess] = useState(false);

  // SELECT DRIVER LICENSE HANDLER
  const uploadDriverLicense = (e) => {
    e.persist();

    if (e.target.files.length > 0) {
      let docFile = e.target.files[0];
      setLicenseImgpreview(URL.createObjectURL(docFile));
      var licenseForm = new FormData();
      licenseForm.append("image", e.target.files[0]);
      setLicense(docFile);
    }
  };


  // SELECT COMPANY REGISTRATION CERTIFICATE HANDLER
  const uploadRegistrationCertificate = (e) => {
    e.persist();

    if (e.target.files.length > 0) {
      let docFile = e.target.files[0];
      if (docFile?.type === "application/pdf") {
        setCertificateImgpreview([]);
        setRegistration(docFile);
        setPdfName(docFile?.name);
      } else {
        setCertificateImgpreview(URL.createObjectURL(docFile));
        setRegistration(docFile);
        setPdfName("");
      }
    }

  };
  useEffect(() => {
    if (licenseSuccess === true && registrationSuccess === true) {
      handleRedirect();
    }
  }, [licenseSuccess, registrationSuccess]);
  // SUBMIT FORM HANDLER
  const submitHandler = () => {
    if (!registration && !license) {
      setApiMessage(
        "warning",
        "Please upload Photo ID or Driver's license and Company's registration certificate or license"
      );
    } else if (!license) {
      setApiMessage("warning", "Please upload Photo ID or Driver's license");
    } else if (!registration) {
      setApiMessage(
        "warning",
        "Please upload Company's registration certificate or license"
      );
    } else {
      let licenseForm = new FormData();
      licenseForm.append("company_identity", license);
      licenseForm.append("user_id", auth?.id);
      if (license) {
        axios
          .post(URLS.baseURL + URLS.uploadLicense, licenseForm)
          .then((res) => {
            setApiMessage("success", res?.data?.message);
            if (res.data.success === true) {
              setLicenseSuccess(true);
            }
          })
          .catch((err) => {
            setApiMessage("success", err?.response?.data?.message);
          });
      }

      let registrationForm = new FormData();
      registrationForm.append("company_licence", registration);
      registrationForm.append("user_id", auth?.id);

      if (registration) {
        axios
          .post(URLS.baseURL + URLS.uploadRegistration, registrationForm)
          .then((res) => {
            setApiMessage("success", res?.data?.message);
            if (res.data.success === true) {
              setRegistrationSuccess(true);
            }
          })
          .catch((err) => {
            setApiMessage("success", err?.response?.data?.message);
          });
      }
      // if (licenseSuccess && registrationSuccess) {
      //   dispatch(authData({ ...auth, business_verified: "Approved1" }));
      //   saveToStorage("authData", { ...auth, business_verified: "Approved" });
      //   navigate("/companyprofileview");
      //   console.log("hello");
      // }
    }
  };

  const handleRedirect = () => {
    if (licenseSuccess && registrationSuccess) {
      dispatch(authData({ ...auth, business_verified: "Approved1" }));
      saveToStorage("authData", { ...auth, business_verified: "Approved" });
      navigate("/company-profile");
    }
  };
  return (
    <Page title="Verification | QXP">
      <Container max-width="lg">
        <Box className="verification-container">
          <Box className="verified-detail">
            <Box display="flex" justifyContent="center">
              <Typography className="verified-title">
                Before accepting service requests let's get you verified
              </Typography>
            </Box>
            <Typography className="page-describe">
              We need to check that you're really you. It helps us fight fraud
              and keep your business secure.
            </Typography>
            <Box
              display={"flex"}
              justifyContent="center"
              className="upload-wrap"
            >
              <Grid container spacing={3} className="upload-detail">
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={6}
                  lg={6}
                  className="uploadfile-detail"
                >
                  <Box className="border-box">
                    <Typography className="proof-title">
                      Photo ID or Driver's license
                    </Typography>
                    <Typography className="notify">
                      Please upload your picture ID using your camera or upload
                      it from your library
                    </Typography>
                    <Box textAlign="center">
                      {licenseImgpreview.length > 0 ? (
                        <img
                          src={licenseImgpreview}
                          className="license-image"
                          alt="license-image"
                        />
                      ) : (
                        ""
                      )}
                    </Box>
                    <Box
                      style={{ width: "100%" }}
                      display="flex"
                      justifyContent="center"
                    >
                      <label htmlFor="contained-company-license">
                        <Input
                          accept="image/*"
                          id="contained-company-license"
                          // multiple
                          type="file"
                          onChange={uploadDriverLicense}
                          // src={licenseImgpreview}
                        />

                        <Button
                          className="upload-license"
                          disableElevation
                          variant="contained"
                          component="span"
                          startIcon={<UploadIcon className="uploadImg" />}
                        >
                          Upload File
                        </Button>
                      </label>
                    </Box>
                  </Box>
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={6}
                  lg={6}
                  className="uploadfile-detail"
                >
                  <Box className="border-box">
                    <Typography className="proof-title">
                      Company's registration certificate or license
                    </Typography>
                    <Typography className="notify">
                      Please upload your company's registration with your state
                      and your business license if applicable in jpeg/pdf
                      format.
                    </Typography>
                    <Box textAlign="center">
                      {certificateImgpreview?.length > 0 ? (
                        <img
                          src={certificateImgpreview}
                          className="license-image"
                          alt="certificate-image"
                        />
                      ) : (
                        ""
                      )}
                      {certificateImgpreview?.length === 0 &&
                      registration?.type === "application/pdf" ? (
                        <img
                          src={pdfPlaceholderImg}
                          className="license-image"
                        />
                      ) : (
                        ""
                      )}
                    </Box>
                    <Box textAlign="center">{pdfName}</Box>
                    <Box
                      style={{ width: "100%" }}
                      display="flex"
                      justifyContent="center"
                      className="upload-file"
                    >
                      <label htmlFor="contained-company-registration">
                        <Input
                          accept="image/jpeg,application/pdf"
                          id="contained-company-registration"
                          // multiple
                          type="file"
                          onChange={uploadRegistrationCertificate}
                        />
                        <Button
                          disableElevation
                          variant="contained"
                          component="span"
                          className="uploadRegistration"
                          startIcon={<UploadIcon className="uploadImg" />}
                        >
                          Upload File
                        </Button>
                      </label>
                    </Box>
                  </Box>
                </Grid>
              </Grid>
            </Box>
            <Box display="flex" justifyContent="center" mb={4}>
              <Button
                className="submit-btn"
                disableElevation
                onClick={submitHandler}
                variant="contained"
              >
                Submit
              </Button>
            </Box>
          </Box>
        </Box>
      </Container>
    </Page>
  );
};

export default VerificationPageView;
