import * as React from "react";
import { NavLink, useLocation } from "react-router-dom";
import {
  AppBar,
  Box,
  Button,
  Drawer,
  IconButton,
  Toolbar,
  Typography,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import CloseIcon from "@mui/icons-material/Close";
import AppLogo from "../../../component/Logo";
import "./topbar.scss";

const menus = [
  { label: "Home", href: "/" },
  { label: "About Us", href: "/about" },
  { label: "Help Center", href: "/faq" },
  { label: "Privacy Policy", href: "/privacy-policy" },
  { label: "Register", href: "/auth/register" },
  // { label: "My Profile", href: "/profile" },
];

export default function PrimarySearchAppBar() {
  const location = useLocation();
  const [open, setOpen] = React.useState(false);

  const drawerToggle = () => {
    setOpen(!open);
  };
  return (
    <Box sx={{ flexGrow: 1 }} className="client-topbar">
      <AppBar position="static" elevation={0}>
        <Toolbar>
          <IconButton
            className="img-logo"
            size="large"
            edge="start"
            color="inherit"
            aria-label="open drawer"
            sx={{ mr: 2 }}
            component={NavLink}
            to={"/"}
            disableRipple
            disableFocusRipple
            disableTouchRipple
          >
            <AppLogo />
          </IconButton>
          <Typography
            variant="h6"
            noWrap
            component="div"
            sx={{ display: { xs: "none", sm: "block" } }}
          ></Typography>
          <Box sx={{ flexGrow: 1 }} />
          <Box sx={{ display: { xs: "none", md: "flex" } }}>
            {menus.map((opt, i) => {
              return (
                <Button
                  disableRipple
                  disableFocusRipple
                  disableTouchRipple
                  key={i}
                  component={NavLink}
                  to={opt?.href}
                  className={
                    location.pathname === opt?.href
                      ? "header-btn active"
                      : "header-btn"
                  }
                >
                  {opt?.label}
                </Button>
              );
            })}

            <Button
              variant="contained"
              disableElevation
              disableRipple
              disableFocusRipple
              disableTouchRipple
              component={NavLink}
              to="/auth/sign-in"
              className="signin-btn"
            >
              Sign In
            </Button>
          </Box>
          <Box sx={{ display: { xs: "flex", md: "none" } }}>
            <IconButton onClick={drawerToggle}>
              <MenuIcon style={{ color: "#000" }} />
            </IconButton>
          </Box>
        </Toolbar>
      </AppBar>

      <Drawer
        anchor="left"
        open={open}
        onClose={drawerToggle}
        className="responsive-drawer"
      >
        <Box className="drawer-box">
          <Box display="flex" justifyContent="flex-end">
            <IconButton onClick={drawerToggle}>
              <CloseIcon />
            </IconButton>
          </Box>

          <Box display="grid" style={{ justifyContent: "center" }}>
            {menus?.map((opt, i) => {
              return (
                <Button
                  disableRipple
                  disableFocusRipple
                  disableTouchRipple
                  key={i}
                  component={NavLink}
                  to={opt?.href}
                  onClick={drawerToggle}
                  className={
                    location.pathname === opt?.href
                      ? "header-btn active"
                      : "header-btn"
                  }
                >
                  {opt?.label}
                </Button>
              );
            })}

            <Button
              variant="contained"
              disableElevation
              disableRipple
              disableFocusRipple
              disableTouchRipple
              component={NavLink}
              to="/auth/sign-in"
              className="signin-btn"
            >
              Sign In
            </Button>
          </Box>
        </Box>
      </Drawer>
    </Box>
  );
}
