import { React, Fragment, useState } from "react";
import { NavLink, useLocation } from "react-router-dom";
import { NavHashLink } from "react-router-hash-link";
import {
  Box,
  Container,
  Grid,
  IconButton,
  InputAdornment,
  TextField,
  Typography,
  Link,
} from "@mui/material";
// import { Button } from "@mui/material";
import MailchimpSubscribe from "react-mailchimp-subscribe";
// import { Email } from "@mui/icons-material";
import { ReactComponent as FooterLogo } from "../../../assets/images/footer-logo.svg";
import { ReactComponent as FacebookIcon } from "../../../assets/images/icons/facebookicon.svg";
import { ReactComponent as TwitterIcon } from "../../../assets/images/icons/twittericon.svg";
import { ReactComponent as InstagramIcon } from "../../../assets/images/icons/instagramicon.svg";
import { ReactComponent as LinkedInIcon } from "../../../assets/images/icons/linkedinicon.svg";
import { ReactComponent as ShareIcon } from "../../../assets/images/icons/shareicon.svg";
import "./footer.scss";

const aboutOpt = [
  { label: "Company", href: "/about" },
  { label: "Blog", href: "/blog" },
  // { label: "Service Agreement", href: "/service-agreements" },
];

const policyOpt = [
  { label: "Privacy Policy", href: "/privacy-policy" },
  { label: "Terms and Conditions", href: "/termscondition" },
];

const contactOpt = [
  { label: "Contact us", href: "/#help-box", id: "contactus" },
  { label: "FAQ", href: "/faq", id: "faq" },
];

// a Newsletter form
const url =
  "https://gmail.us14.list-manage.com/subscribe/post?u=443c497790c4ac66ac3e600d8&id=deda2be7fc";

const NewsletterForm = ({ status, message, onValidated }) => {
  const [email, setEmail] = useState("");

  const submit = () => {
    onValidated({
      EMAIL: email,
    });
    setEmail("");
  };
  return (
    <Fragment>
      <TextField
        className="email-text"
        // variant="outlined"
        size="small"
        onChange={(e) => setEmail(e.target.value)}
        type="email"
        value={email}
        placeholder="Enter your email address"
        focused
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton onClick={submit}>
                <ShareIcon className="shareicon" />
              </IconButton>
            </InputAdornment>
          ),
        }}
      />
      <Box
        style={{
          padding: 10,
          display: "block",
        }}
      >
        {status === "sending" && (
          <Box className="newsletter-mail send">sending...</Box>
        )}
        {status === "error" && (
          <Box
            className="newsletter-mail error"
            dangerouslySetInnerHTML={{
              __html:
                message === "0 - An email address must contain a single @"
                  ? "Enter Valid Email Address"
                  : message === "0 - Please enter a value"
                  ? "Enter Your Email Address"
                  : message,
            }}
          />
        )}
        {status === "success" && (
          <Box
            className="newsletter-mail success"
            dangerouslySetInnerHTML={{ __html: message }}
          />
        )}
      </Box>
    </Fragment>
  );
};

const Footer = () => {
  const location = useLocation();

  let footerVisible =
    window.location.pathname.includes("/profile") ||
    window.location.pathname.includes("/company") ||
    window.location.pathname.includes("/customer");
  return (
    <Fragment>
      {footerVisible ? (
        ""
      ) : (
        <Box
          className={
            location.pathname === "/"
              ? "client-footer home-ff-top"
              : "client-footer"
          }
        >
          <Container maxWidth="lg">
            <Box>
              <Grid container spacing={3}>
                <Grid className="menu-block" item lg={2} sm={4} md={4} xs={12}>
                  <Typography variant="body1" className="sec-title">
                    About
                  </Typography>

                  {/* OPTIONS */}
                  <Box className="opt-menus">
                    {aboutOpt?.map((x, i) => {
                      return (
                        <NavLink className="opt-menu" to={x?.href} key={i}>
                          {x?.label}
                        </NavLink>
                      );
                    })}
                  </Box>
                </Grid>
                <Grid className="menu-block" item lg={2} sm={4} md={4} xs={12}>
                  <Typography variant="body1" className="sec-title">
                    Policy
                  </Typography>

                  {/* OPTIONS POLICIES */}
                  <Box className="opt-menus">
                    {policyOpt?.map((p, m) => {
                      return (
                        <NavLink className="opt-menu" to={p?.href} key={m}>
                          {p.label}
                        </NavLink>
                      );
                    })}
                  </Box>
                </Grid>
                <Grid className="menu-block" item lg={2} sm={4} md={4} xs={12}>
                  <Typography variant="body1" className="sec-title">
                    Help Center
                  </Typography>

                  {/* HELP CENTER OPTION */}
                  <Box className="opt-menus">
                    {contactOpt.map((c, h) => {
                      return (
                        <>
                          {c.label === "Contact us" ? (
                            <NavHashLink
                              to={c?.href}
                              className="opt-menu"
                              smooth
                            >
                              {c.label}
                            </NavHashLink>
                          ) : (
                            <NavLink
                              className="opt-menu"
                              to={c?.href}
                              key={h}
                              id={c.id}
                            >
                              {c.label}
                            </NavLink>
                          )}
                        </>
                      );
                    })}
                    {/* <HashLink smooth className="opt-menu">
                    Section Two
                  </HashLink> */}
                  </Box>
                </Grid>

                {/* SUSCRIBE NEWSLETTER */}
                <Grid
                  item
                  lg={6}
                  sm={12}
                  md={12}
                  xs={12}
                  className="responsive-padding"
                >
                  <Typography variant="body1" className="sec-title">
                    SUBSCRIBE OUR NEWSLETTER
                  </Typography>
                  <Typography className="newsletter-text">
                    We will send you our daily updates for business growth
                  </Typography>
                  {/* FOOTER SEND MAIL */}
                  <MailchimpSubscribe
                    url={url}
                    render={({ subscribe, status, message }) => (
                      <NewsletterForm
                        status={status}
                        message={message}
                        onValidated={(formData) => subscribe(formData)}
                      />
                    )}
                  />
                </Grid>
              </Grid>
            </Box>
            {/* FOOTER */}
            <Box className="copyright">
              {/* COPYRIGHT */}
              <Box className="copyright-text-icon">
                <FooterLogo />

                <Typography className="copyright-text">
                  2022 Copyright, All rights Reserved
                </Typography>
              </Box>

              {/* SOCIALMEDIA ICONS */}
              <Box className="socialmedia-icon">
                <Link target="_blank" href="#">
                  <FacebookIcon className="icon" />
                </Link>
                <Link target="_blank" href="#">
                  <TwitterIcon className="icon" />
                </Link>
                <Link target="_blank" href="#">
                  <InstagramIcon className="icon" />
                </Link>
                <Link target="_blank" href="#">
                  <LinkedInIcon className="icon" />
                </Link>
              </Box>
            </Box>
          </Container>
        </Box>
      )}
    </Fragment>
  );
};

export default Footer;
