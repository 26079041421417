import React, { useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import { URLS } from "../../../helpers/url";
import { useDispatch } from "react-redux";
import { authData } from "../../../helpers/stores/actions/auth";
import { saveToStorage } from "../../../helpers/storage";
import { setApiMessage } from "../../../helpers/notification";
import Page from "../../../component/Page";
import * as Yup from "yup";
import { Formik } from "formik";
import axios from "axios";
import {
  Box,
  RadioGroup,
  FormControlLabel,
  Radio,
  TextField,
  Checkbox,
  Button,
} from "@mui/material";
import "../auth.scss";
import { Link } from "react-router-dom";
import { ReactComponent as AuthLogoLogin } from "../../../assets/images/auth-login.svg";

const SigninPageView = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [whoIs, setWhoIs] = useState("customer");
  return (
    <Page title="Sign in">
      <Box className="auth-wrap signin" component={"section"}>
        <Box className="col-wrap">
          <Box className="col-left">
            <Box className="form-wrap primary">
              <h1>Login</h1>

              <Formik
                initialValues={{
                  email: "",
                  password: "",
                }}
                validationSchema={Yup.object().shape({
                  email: Yup.string()
                    .email("Must be a valid email.")
                    .max(255)
                    .required("This field is required."),
                  password: Yup.string()
                    .required("This field is required.")
                    .min(6, "Min 6 characters required."),
                })}
                onSubmit={(formVal) => {
                  let bodyFormData = new FormData();

                  bodyFormData.append("email", formVal.email);
                  bodyFormData.append("password", formVal.password);
                  bodyFormData.append("type", whoIs);

                  axios
                    .post(URLS.baseURL + URLS.login, bodyFormData)
                    .then((res) => {
                      if (res?.data?.data) {
                        // dispatch(authData(res?.data?.data));
                        // saveToStorage("authData", res?.data?.data);
                        dispatch(
                          authData({ ...res?.data?.data, user_type: whoIs })
                        );
                        saveToStorage("authData", {
                          ...res?.data?.data,
                          user_type: whoIs,
                        });

                        if (
                          whoIs === "company" &&
                          res?.data?.data?.document_uploaded == true &&
                          res?.data?.data?.business_verified !== "Rejected"
                        ) {
                          navigate("/company-profile");
                        }
                        if (res?.data?.data?.user_verified === 0) {
                          navigate("/auth/verify");
                        }
                      }

                      setApiMessage("success", res?.data?.message);
                    })
                    .catch((err) => {
                      setApiMessage("error", err?.response?.data?.message);
                    });
                }}
              >
                {({
                  errors,
                  handleBlur,
                  handleChange,
                  handleSubmit,
                  touched,
                  values,
                }) => (
                  <form onSubmit={handleSubmit}>
                    <Box className="form-inner">
                      <Box className="radio-wrap">
                        <lable>Login as a</lable>
                        <RadioGroup
                          aria-labelledby="demo-radio-buttons-group-label"
                          defaultValue="customer"
                          name="radio-buttons-group"
                          row
                          disableElevation
                          disableRipple
                          disableFocusRipple
                          disableTouchRipple
                        >
                          <FormControlLabel
                            value="customer"
                            control={
                              <Radio
                                checked={whoIs === "customer"}
                                onClick={() => {
                                  setWhoIs("customer");
                                }}
                              />
                            }
                            label="Customer"
                          />
                          <FormControlLabel
                            value="company"
                            control={
                              <Radio
                                checked={whoIs === "company"}
                                onClick={() => {
                                  setWhoIs("company");
                                }}
                              />
                            }
                            label="Company"
                          />
                        </RadioGroup>
                      </Box>
                      <Box className="input-wrap">
                        <lable>Email Address</lable>
                        <TextField
                          error={Boolean(touched.email && errors.email)}
                          helperText={touched.email && errors.email}
                          id="email"
                          placeholder="Your email"
                          variant="outlined"
                          focused
                          name="email"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.email}
                        />
                      </Box>
                      <Box className="input-wrap">
                        <lable>Password</lable>
                        <TextField
                          error={Boolean(touched.password && errors.password)}
                          helperText={touched.password && errors.password}
                          id="password"
                          type="password"
                          placeholder="Your Password"
                          variant="outlined"
                          focused
                          name="password"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.password}
                        />
                      </Box>
                      <Box className="remember-wrap">
                        <FormControlLabel
                          control={<Checkbox />}
                          label="Remember me"
                        />
                        <NavLink
                          className="forgotpwd"
                          to="/auth/forgot-password"
                        >
                          Forgot passwod?
                        </NavLink>
                      </Box>
                      <Button type="submit" className="submit-btn" fullWidth>
                        Log In
                      </Button>
                      <p className="account-text">
                        Don't have account?
                        <b>
                          <NavLink
                            className="createaccount"
                            to="/auth/register"
                          >
                            Create an account
                          </NavLink>
                        </b>
                      </p>
                    </Box>
                  </form>
                )}
              </Formik>
            </Box>
          </Box>
          <Box className="col-right">
            <Box className="content">
              <AuthLogoLogin />
            </Box>
          </Box>
        </Box>
      </Box>
    </Page>
  );
};

export default SigninPageView;
