import React, { forwardRef, useEffect } from "react";
import { Helmet } from "react-helmet";

const Page = forwardRef(({ children, title = "", ...rest }, ref) => {
  useEffect(() => {
    window.scroll({
      top: 0,
      left: 0,
      behavior: "smooth",
    });

    // eslint-disable-next-line
  }, [window.location.pathname]);
  return (
    <div ref={ref} {...rest}>
      <Helmet>
        <title>{title}</title>
      </Helmet>
      {children}
    </div>
  );
});

export default Page;
