import React from "react";
import { ToastContainer } from "react-toastify";
import { Outlet } from "react-router-dom";
import TopBar from "./TopBar";
import Footer from "./Footer";

const ClientLayout = () => {
  return (
    <div>
      <ToastContainer />
      <TopBar />

      <div>
        <Outlet />
      </div>
      <Footer />
    </div>
  );
};

export default ClientLayout;
