import React, { useEffect } from "react";
import { Avatar, Box, Container, Grid, Typography, Link } from "@mui/material";
import { ReactComponent as AppStoreIcon } from "../../assets/images/app-store.svg";
import { ReactComponent as PlayStoreIcon } from "../../assets/images/play-store.svg";
import Download from "../../assets/images/download.png";
import Page from "../../component/Page";
import Help1Img from "../../assets/images/help-1.png";
import Help2Img from "../../assets/images/help-2.png";
import Team1Img from "../../assets/images/team-1.png";
import Team2Img from "../../assets/images/team-2.png";
import Team3Img from "../../assets/images/team-3.png";
import { WOW } from "wowjs";
import "./about.scss";

const AboutView = () => {
  useEffect(() => {
    const wow = new WOW({ live: false }); // disables sync requirement
    wow.init();
  }, []);

  return (
    <Page title="About | QXP">
      <Container maxWidth="lg">
        <Box className="about-container wow fadeInUp">
          <Box display="flex" justifyContent="center">
            <Typography variant="h1">
              We help business to grow their customer base
            </Typography>
          </Box>

          <Box display="flex" justifyContent="center ">
            <Typography className="sub-head-text wow fadeInUp">
              QXP was born with a simple mission, to help customers to find any
              service provider without the hassle. QXP is the ultimate solution
              for a quick connection between a customer and a service provider
              using the latest innovative platform of its kind.
            </Typography>
          </Box>

          <Box className="top-imgs">
            <Grid container spacing={3} className="img-container">
              <Grid
                item
                xs={12}
                sm={12}
                md={6}
                lg={6}
                classname="padding-remove wow slideInLeft"
              >
                <Avatar variant="square" src={Help1Img} className="htop-1" />
              </Grid>

              <Grid
                item
                xs={12}
                sm={12}
                md={6}
                lg={6}
                className="padding-remove wow slideInRight"
              >
                <Avatar variant="square" src={Help2Img} className="htop-2" />
              </Grid>
            </Grid>
          </Box>

          {/* EMPOWE SECTION */}
          <Box className="empower-section">
            <Grid container spacing={3} className="empower-container">
              <Grid
                item
                xs={12}
                sm={12}
                md={6}
                lg={6}
                className=" wow slideInLeft"
              >
                <Typography variant="h4">
                  Empower business to achieve success.
                </Typography>
              </Grid>
              <Grid
                item
                xs={12}
                sm={12}
                md={6}
                lg={6}
                className=" wow slideInRight"
              >
                <Typography className="desc">
                  You know how frustrating it can be when you need a service
                  provider like a painter, plumber or an electrician quickly and
                  all you get is nothing but a busy phone line, a “ let me take
                  your information and will call you back” right?
                </Typography>
                <br className="br" />
                <Typography className="desc">
                  Maybe you’re a service provider who finds it hard to connect
                  with those who need your service?
                </Typography>
                <br className="br" />
                <Typography className="desc">
                  We know how that feels, we have been there, and that's why
                  we’ve come up with a solution. offshoring.
                </Typography>
              </Grid>
            </Grid>
          </Box>

          <Box className="empower-section">
            <Grid container spacing={3} className="empower-container">
              <Grid
                item
                xs={12}
                sm={12}
                md={12}
                lg={12}
                className=" wow slideInLeft"
              >
                <Typography variant="h4" className="recue-title">
                  Here’s QXP app to the rescue.
                </Typography>
              </Grid>
              <Grid
                item
                xs={12}
                sm={12}
                md={12}
                lg={12}
                className=" wow slideInRight"
              >
                <Typography className="desc">
                  With the QXP (Quick Provider) app, you can search for any
                  service provider near you and the QXP app will quickly locate
                  and connect you with the first available service provider
                  based on your search. You can even ask SIRI; “hey Siri, ask
                  QXP to find a plumber” and Siri will find it for you.
                </Typography>
                <br className="br" />
                <Typography className="desc">
                  Then you will see the available provider instantly on your
                  phone screen, and once you accept it , you will receive a call
                  immediately and make an appointment.
                </Typography>
                <br className="br" />
                <Typography className="desc">
                  As a service provider, the QXP app connects you to customers
                  for the services you provide and quickly connects you and then
                  alerts you to pending requests. This will help you to reduce
                  your monthly advertising costs and increase your customers
                  with no efforts at all.
                </Typography>
                <br className="br" />
                <Typography className="desc">
                  It’s that simple. The QXP app is the ultimate solution for a
                  customer and service provider.
                </Typography>
                <br className="br" />
                <Typography className="desc">
                  With the QXP app, no more wasting time and effort on a random
                  search for the service you need, or resources trying to reach
                  those who need and when you need them. The QXP app brings the
                  service providers to your doorstep with no time wasted
                  searching and calling or clients straight to you without the
                  efforts finding them.
                </Typography>
                <br className="br" />
                <Typography className="desc">
                  Download the QXP app today and get what you need quickly!
                </Typography>
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Container>
      <Box className="bluebg">
        <Container>
          <Grid container className="download-info">
            <Grid
              item
              className="download-app wow fadeInLeft"
              xs={12}
              sm={12}
              md={6}
              lg={6}
            >
              <Typography className="download-head">
                Download the app now & use it.
              </Typography>
              <Box className="store-links">
                <Link target="_blank" href="#">
                  <AppStoreIcon className="app-store" />
                </Link>
                <Link target="_blank" href="#">
                  <PlayStoreIcon className="play-store" />
                </Link>
              </Box>
            </Grid>
            <Grid
              item
              className="homesec wow fadeInUp"
              xs={12}
              sm={12}
              md={6}
              lg={6}
            >
              <Avatar
                src={Download}
                variant="square"
                className="download-img"
              />
            </Grid>
          </Grid>
        </Container>
      </Box>
      <Container maxWidth="lg">
        <Box className="about-container wow fadeInUp">
          {/* MEET OUR TEAM */}
          <Box className="team-section">
            <Box className="wow fadeInUp">
              <Typography variant="h3">Meet our team</Typography>
              <Box display="flex" justifyContent="center">
                <Typography variant="body1" className="desc">
                  Leverage agile frameworks to provide a robust synopsis for
                  high level overviews. Iterative approaches to corporate
                  strategy.
                </Typography>
              </Box>

              <Grid container spacing={4} className="team-container ">
                <Grid
                  item
                  xs={12}
                  sm={6}
                  md={4}
                  lg={4}
                  className="wow slideInLeft"
                >
                  <Avatar variant="square" src={Team1Img} />
                  <Typography className="name">David Martin</Typography>
                  <Typography className="position">Founder & CTO</Typography>
                </Grid>

                <Grid
                  item
                  xs={12}
                  sm={6}
                  md={4}
                  lg={4}
                  className="wow fadeInUpwow fadeInUp "
                >
                  <Avatar variant="square" src={Team2Img} />
                  <Typography className="name">David Martin</Typography>
                  <Typography className="position">
                    Creative Director
                  </Typography>
                </Grid>

                <Grid
                  item
                  xs={12}
                  sm={6}
                  md={4}
                  lg={4}
                  className="wow slideInRight"
                >
                  <Avatar variant="square" src={Team3Img} />
                  <Typography className="name">David Martin</Typography>
                  <Typography className="position">Design Head</Typography>
                </Grid>
              </Grid>
            </Box>
          </Box>
        </Box>
      </Container>
    </Page>
  );
};

export default AboutView;
