import React from "react";
import { useRoutes } from "react-router-dom";
import { useSelector } from "react-redux";
import { fetchFromStorage } from "./helpers/storage";
import { routes, privateRoutes, verifyRoutes } from "./routes";

function App() {
  const reduxUser = useSelector((state) => state?.AuthDatas)?.authData;
  const auth = reduxUser ? reduxUser : fetchFromStorage("authData");

  const routing = useRoutes(
    auth
      ? auth?.user_verified !== 0 && auth?.business_verified !== "Approved"
        ? verifyRoutes
        : auth?.user_verified !== 0
        ? privateRoutes
        : routes
      : routes
  );
  return <div className="App">{routing}</div>;
}

export default App;
