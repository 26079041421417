import React from "react";
import { Navigate } from "react-router-dom";

// LAYOUTS
import ClientLayout from "./layouts/ClientLayout";
import AuthLayout from "./layouts/AuthLayout";
import UserLayout from "./layouts/UserLayout";

// PAGES
import SigninPageView from "./view/Auth/SignIn";
import RegisterPageView from "./view/Auth/Signup";
import ForgotPwdview from "./view/Auth/ForgotPassword";
import VerifyOTP from "./view/Auth/VerifyOTP";
import HomePageView from "./view/Home";
import AboutPageView from "./view/About";
import HelpCenterPageView from "./view/HelpCenter";
import PrivacyPolicyPageView from "./view/PrivacyPolicy";
import FaqPageView from "./view/Faq";
import Verification from "./view/Verification";
// PRIVATE ROUTES
import MyProfilePageView from "./view/MyProfile";
import MyCompanyProfilePageView from "./view/MyProfile/company";
import VerificationPageView from "./view/Verification";
import TermandCondition from "./view/Terms&condition";
import CompanyProfilePageView from "./view/MyProfile/company";

export const routes = [
  {
    path: "/",
    element: <ClientLayout />,
    children: [
      { path: "/", element: <HomePageView /> },
      { path: "/about", element: <AboutPageView /> },
      // { path: "/help-center", element: <HelpCenterPageView /> },
      { path: "/privacy-policy", element: <PrivacyPolicyPageView /> },
      { path: "/faq", element: <FaqPageView /> },
      { path: "*", element: <Navigate to="/" /> },
      { path: "/termscondition", element: <TermandCondition /> },
    ],
  },
  {
    path: "/auth",
    element: <AuthLayout />,
    children: [
      { path: "/auth/sign-in", element: <SigninPageView /> },
      { path: "/auth/register", element: <RegisterPageView /> },
      { path: "/auth/forgot-password", element: <ForgotPwdview /> },
      { path: "/auth/verify", element: <VerifyOTP /> },
      { path: "*", element: <Navigate to="/" /> },
    ],
  },
];

export const privateRoutes = [
  {
    path: "/",
    element: <UserLayout />,
    children: [
      { path: "/companyprofile", element: <CompanyProfilePageView /> },
      { path: "/termscondition", element: <TermandCondition /> },
      { path: "/", element: <HomePageView /> },
      { path: "/about", element: <AboutPageView /> },
      { path: "/faq", element: <FaqPageView /> },
      // { path: "/help-center", element: <HelpCenterPageView /> },
      { path: "/privacy-policy", element: <PrivacyPolicyPageView /> },
      { path: "/auth/register", element: <RegisterPageView /> },
      { path: "/", element: <Navigate to="/profile" /> },
      { path: "/profile", element: <MyProfilePageView /> },
      { path: "/company-profile", element: <MyCompanyProfilePageView /> },
      { path: "/companyprofile", element: <VerificationPageView /> },
      { path: "*", element: <Navigate to="/profile" /> },
    ],
  },
];

export const verifyRoutes = [
  {
    path: "/",
    element: <UserLayout />,
    children: [
      { path: "/", element: <HomePageView /> },
      { path: "/about", element: <AboutPageView /> },
      { path: "/faq", element: <FaqPageView /> },
      // { path: "/help-center", element: <HelpCenterPageView /> },
      { path: "/privacy-policy", element: <PrivacyPolicyPageView /> },
      { path: "/termscondition", element: <TermandCondition /> },
      { path: "/company", element: <Verification /> },
      { path: "*", element: <Navigate to="/company" /> },
      { path: "/profile", element: <MyProfilePageView /> },
      { path: "/company-profile", element: <MyCompanyProfilePageView /> },
    ],
  },
];
