import React, { useState, useRef, useEffect, Component } from "react";
import {
  Avatar,
  Box,
  Button,
  Link,
  Container,
  Grid,
  TextField,
  Typography,
} from "@mui/material";
import Rating from "@mui/material/Rating";
import Page from "../../component/Page";
import { Formik } from "formik";
import { ReactComponent as AppStoreIcon } from "../../assets/images/app-store.svg";
import { ReactComponent as PlayStoreIcon } from "../../assets/images/play-store.svg";
import HomeSec2 from "../../assets/images/sec-2.png";
import HomeSec3 from "../../assets/images/homesec3.svg";
import HomeSec4 from "../../assets/images/homesec4.svg";
import Client from "../../assets/images/Client.png";
import Client1 from "../../assets/images/Client1.jpg";
import Client2 from "../../assets/images/Client2.jpg";
import Client3 from "../../assets/images/Client3.jpg";
import Quote from "../../assets/images/quote icon.png";
import Download from "../../assets/images/download.png";
import IntroQXP from "../../assets/media/introvideo.MP4";
// import PauseIcon from "../../assets/images/icons/pauseicon.png";
import PlayIcon from "../../assets/images/icons/playicon.svg";
import * as Yup from "yup";
import { ReactComponent as RocketIcon } from "../../assets/images/rocket.svg";
import { ReactComponent as Quick } from "../../assets/images/quick.svg";
import { ReactComponent as MarketPlace } from "../../assets/images/marketplace.svg";
import { ReactComponent as Iphone } from "../../assets/images/iphone.svg";
import { ReactComponent as OneIcon } from "../../assets/images/one.svg";
import { ReactComponent as Service } from "../../assets/images/service.svg";
import { ReactComponent as TwoIcon } from "../../assets/images/two.svg";
import { ReactComponent as DoneIcon } from "../../assets/images/done.svg";
import { ReactComponent as ThreeIcon } from "../../assets/images/three.svg";
import { ReactComponent as RightSign } from "../../assets/images/rightsign.svg";
import { ReactComponent as Clientdetail } from "../../assets/images/client-detail.svg";
import axios from "axios";
import { URLS } from "../../helpers/url";
import { setApiMessage } from "../../helpers/notification";
import Slider from "react-slick";
// import { Carousel } from "react-responsive-carousel";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { WOW } from "wowjs";
import $ from "jquery";

// import { useDispatch } from "react-redux";
// import { useNavigate } from "react-router-dom";
import "./home.scss";

const HomePageView = () => {
  useEffect(() => {
    const wow = new WOW({ live: false }); // disables sync requirement
    wow.init();
  }, []);

  // const navigate = useNavigate();
  // const dispatch = useDispatch();

  const [getinfo] = useState({}); //setGetInfo

  const [play, setPlay] = useState(false);
  const [currentIndex, setcurrentIndex] = useState();
  const [nextArrow, setnextArrow] = useState(true);
  const [prevArrow, setprevArrow] = useState(false);

  useEffect(() => {
    if ($("body .slick-next").hasClass("slick-disabled")) {
      setnextArrow(false);
    } else {
      setnextArrow(true);
    }
    if ($("body .slick-prev").hasClass("slick-disabled")) {
      setprevArrow(false);
    } else {
      setprevArrow(true);
    }
  }, [currentIndex]);
  const formikRef = useRef();

  const settings = {
    dots: false,
    infinite: false,
    slidesToShow: 1,
    slidesToScroll: 1,
    // autoplay: true,
    speed: 500,
    arrows: true,
    // autoplaySpeed: 2000,
    cssEase: "linear",
    beforeChange: (current, next) => {
      setcurrentIndex(current);
    },
  };

  const next = (index) => {
    Slider.current.slickNext();
  };
  const previous = () => {
    Slider.current.slickPrev();
  };

  const Testimonials = [
    {
      id: 1,
      content:
        "Qxp is the app i always wanted. Finally , I can have a waiter for my parties quickly as possible. Ask Siri , ask Qxp to find a waiter and boom I got a call from available waiter in the area. I still cannot believe this is possible",
      image: Client,
      quote: Quote,
      name: "Max",
    },
    {
      id: 2,
      content:
        "I love this app. I always had an issue  get a service provider out to my house. This app made everything so fast and simple. I requested a roofer to repair my roof and in seconds I got a call from a professional roofing company and we arranged a day and time for the repairs. If you did not yet, download the app now!!! It is a life saver.",
      image: Client3,
      quote: Quote,
      name: "Isabella",
    },
    {
      id: 3,
      content:
        "Why google a service provider if you need one? Use the QXP app and have one on the phone in seconds. You can even ask Siri and she does the work for you. This app made my life much easier when it comes to find a service provider. ",
      image: Client1,
      quote: Quote,
      name: "Armando",
    },
    {
      id: 4,
      content:
        "My air-conditioner stopped cooling my house. I was on the phone for hours to find someone to come out as soon as possible as the heat in Florida is too intense. My friend said , use the QXP app. an do not waste your time searching So I did and in 15 seconds a licensed air-conditioner contractor called me and he was out at my house and fixed my unit in less than an hour. This is a must have rescue app. I love it.",
      image: Client2,
      quote: Quote,
      name: "Bill",
    },
  ];

  return (
    <Page title="QXP">
      <Box className="home-page-container">
        <Container maxWidth="lg">
          <Box component="section" className="home-top-sec-1">
            <Grid container spacing={3}>
              <Grid
                item
                xs={12}
                sm={12}
                md={6}
                lg={6}
                className="wow slideInLeft"
              >
                <Typography variant="h2" className="service-heading">
                  Get a professional service provider quickly!
                </Typography>

                <Typography className="service-info">
                  Search services and get connected with the selected provider
                  in seconds. Fastest way to get connected with a qualified
                  provider!
                </Typography>

                <Box className="store-links">
                  <Link target="_blank" href="https://www.google.com/">
                    <AppStoreIcon className="app-store" />
                  </Link>
                  <Link target="_blank" href="#">
                    <PlayStoreIcon />
                  </Link>
                </Box>
              </Grid>
              <Grid
                item
                xs={12}
                sm={12}
                md={6}
                lg={6}
                className="wow fadeInRight"
              >
                {/* <Avatar src={Homesec1} variant="square" className="home-sec1" /> */}
                <Box className="videoplayer" id="videoplayer">
                  <video
                    width="100%"
                    controls={play ? true : false}
                    id="myVideo"
                    className="video-info"
                  >
                    <source src={IntroQXP} type="video/mp4"></source>
                  </video>

                  {play ? null : (
                    <Button
                      className="play-btn"
                      onClick={() => {
                        var x = document.getElementById("myVideo");
                        x.play();
                        setPlay(true);
                      }}
                    >
                      <img src={PlayIcon} alt="play-icon" />
                    </Button>
                  )}
                </Box>
              </Grid>
            </Grid>
          </Box>

          {/* HELPING YOU FIND SECTION */}
          <Box component="section" className="helping-section">
            <Grid container spacing={3}>
              <Grid
                item
                xs={12}
                sm={12}
                md={6}
                lg={6}
                className="wow slideInLeft"
              >
                <Avatar src={HomeSec2} variant="square" className="home-sec2" />
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={6}>
                <Typography variant="h2" className="wow slideInRight">
                  Helping you find many available services in your area in
                  seconds
                </Typography>
                <Typography className="wow slideInRight">
                  Start searching service like Plumber, Car Repair, AC Repair.
                </Typography>

                <Box className="options">
                  <Box className="option wow fadeInUp">
                    <Box className="opt-icon">
                      <RocketIcon className="quick" />
                    </Box>
                    <Box className="opt-txt">
                      <Typography className="head">
                        1000+ Services options
                      </Typography>
                      <Typography className="describe">
                        Access Wide Range of Services on our Platform,
                      </Typography>
                    </Box>
                  </Box>
                  <Box className="option wow fadeInUp">
                    <Box className="opt-icon">
                      <Quick className="quick" />
                    </Box>
                    <Box className="opt-txt">
                      <Typography className="head">
                        Get reponse quickly!
                      </Typography>
                      <Typography className="describe">
                        Get Service Provider Connected in 60 Seconds
                      </Typography>
                    </Box>
                  </Box>
                  <Box className="option wow fadeInUp">
                    <Box className="opt-icon">
                      <MarketPlace className="quick" />
                    </Box>
                    <Box className="opt-txt">
                      <Typography className="head">
                        Best in marketplace
                      </Typography>
                      <Typography className="describe">
                        Handpicked Professionals Only
                      </Typography>
                    </Box>
                  </Box>
                </Box>
              </Grid>
            </Grid>
          </Box>
        </Container>

        <Box className="provider">
          <Container maxWidth="lg">
            <Typography className="app-head wow fadeInUp">
              How to use the quick provider app
            </Typography>
            <Typography className="app-decribe wow fadeInUp">
              Just few easy steps to solve your problem.
            </Typography>
            <Grid container spacing={3} className="grid-provider">
              <Grid
                item
                xs={12}
                sm={12}
                md={4}
                lg={4}
                className="grid-item wow fadeInUp"
              >
                <Typography align="center">
                  <Iphone className="ser-icon" />
                </Typography>

                <Typography align="center">
                  <OneIcon className="numbers" />
                </Typography>
                <Typography className="ser-name" align="center">
                  Onboarding
                </Typography>
                <Typography className="services" align="center">
                  Register as Customer or Service Provider and Enjoy Fastest
                  Services On our Platform
                </Typography>
              </Grid>
              <Grid item xs={12} sm={12} md={4} lg={4} className="wow fadeInUp">
                <Typography align="center">
                  <Service className="ser-icon" />
                </Typography>

                <Typography align="center">
                  <TwoIcon className="numbers" />
                </Typography>
                <Typography className="ser-name" align="center">
                  Request Service
                </Typography>
                <Typography className="services" align="center">
                  Search any service that you need and get the nearest provider
                  on your dashboard just in seconds.
                </Typography>
              </Grid>
              <Grid item xs={12} sm={12} md={4} lg={4} className="wow fadeInUp">
                <Typography align="center">
                  <DoneIcon className="ser-icon" />
                </Typography>
                <Typography align="center">
                  <ThreeIcon className="numbers" />
                </Typography>
                <Typography className="ser-name" align="center">
                  Get it done
                </Typography>
                <Typography className="services last-ser" align="center">
                  Your request you are done! You will receive a notification
                  when the service provider on the way to your home or business.
                </Typography>
              </Grid>
            </Grid>
          </Container>
        </Box>

        <Container maxWidth="lg">
          <Box className="provide-wrap">
            <Grid container spacing={3} className="sec-3grid">
              <Grid
                item
                className="help-head wow fadeInLeft"
                xs={12}
                sm={12}
                md={6}
                lg={6}
              >
                <Typography className="help-head">
                  Quick Provider helps you to search Faster!!
                </Typography>
                <Typography className="help-describe">
                  Start searching for services like painter, roofer, plumber and
                  access wide range of services on our platform, Search services
                  and connect with a service provider in seconds.
                </Typography>
              </Grid>
              <Grid
                item
                className="help-photo  wow fadeInRight"
                xs={12}
                sm={12}
                md={6}
                lg={6}
              >
                <Avatar src={HomeSec3} variant="square" className="home-sec3" />
              </Grid>
            </Grid>
          </Box>

          <Box className="siri">
            <Grid container spacing={3} className="sec-3grid">
              <Grid
                item
                className="help-photo wow fadeInLeft"
                xs={12}
                sm={12}
                md={6}
                lg={6}
              >
                <Avatar src={HomeSec4} variant="square" className="home-sec3" />
              </Grid>
              <Grid
                item
                className="siri-describe"
                xs={12}
                sm={12}
                md={6}
                lg={6}
              >
                <Typography className="siri-head wow fadeInRight">
                  Search services you need with Siri and get fastest results
                  without a tap on your phone screen
                </Typography>
                <Typography className="siri-provider wow fadeInRight">
                  and just watch your phone screen how quick the app will find a
                  service provider in your area, and moments after you will
                  receive a call from the selected provider....
                </Typography>

                <Box className="display-flex wow fadeInUp">
                  <Typography className="vcss">
                    <RightSign className="icon" />
                    100% Verified Providers
                  </Typography>
                  <Typography className="vcss">
                    <RightSign className="icon" />
                    Well Connected Network
                  </Typography>
                </Box>
                <Box className="display-flex margin-bottom wow fadeInUp">
                  <Typography className="vcss">
                    <RightSign className="icon" />
                    24x7 Service Support
                  </Typography>
                  <Typography className="vcss">
                    <RightSign className="icon" />
                    Voice Support
                  </Typography>
                </Box>
                <Box className="get-btn wow fadeInUp">
                  <Link target="_blank" href="#">
                    <Button
                      disableElevation
                      variant="contained"
                      className="started-btn"
                    >
                      Get Started Now
                    </Button>
                  </Link>
                </Box>
              </Grid>
            </Grid>
          </Box>
        </Container>

        <Box className="bluebg">
          <Container>
            <Grid container className="download-info">
              <Grid
                item
                className="download-app wow fadeInLeft"
                xs={12}
                sm={12}
                md={6}
                lg={6}
              >
                <Typography className="download-head">
                  Download the app now & use it.
                </Typography>
                <Box className="store-links">
                  <Link target="_blank" href="#">
                    <AppStoreIcon className="app-store" />
                  </Link>
                  <Link target="_blank" href="#">
                    <PlayStoreIcon className="play-store" />
                  </Link>
                </Box>
              </Grid>
              <Grid
                item
                className="homesec wow fadeInUp"
                xs={12}
                sm={12}
                md={6}
                lg={6}
              >
                <Avatar
                  src={Download}
                  variant="square"
                  className="download-img"
                />
              </Grid>
            </Grid>
          </Container>
        </Box>

        <Box>
          <Container>
            {/* Clients Testimonials */}
            <Slider
              className="slick-slider wow fadeInUp"
              ref={(c) => (Slider.current = c)}
              {...settings}
            >
              {Testimonials.map((item) => {
                return (
                  <Grid container spacing={3} className="client-wrap">
                    <Grid
                      item
                      className="client-detail"
                      xs={12}
                      sm={12}
                      md={6}
                      lg={6}
                    >
                      <Box className="client-pic">
                        <img src={item?.quote} className="quote-img" />
                        <Avatar
                          src={item?.image}
                          variant="square"
                          className="client-photo"
                        />
                      </Box>
                    </Grid>

                    <Grid
                      item
                      className="client-detail"
                      xs={12}
                      sm={12}
                      md={6}
                      lg={6}
                    >
                      <Typography className="client-test">
                        Clients Testimonials
                      </Typography>

                      <Box>
                        <Typography
                          component="legend"
                          className="starts"
                        ></Typography>
                        <Rating name="read-only" value={5} readOnly />
                      </Box>

                      <Typography className="client-describe">
                        {item?.content}
                      </Typography>

                      <Box className="client-detail">
                        <Box>
                          <Typography className="client-name">
                            {item?.name}
                          </Typography>
                        </Box>
                      </Box>
                      <Box className="border-bottom arrow-wrap">
                        {prevArrow ? (
                          <Typography
                            className="previous"
                            onClick={() => previous()}
                          >
                            Prev Story{" "}
                          </Typography>
                        ) : (
                          ""
                        )}

                        {nextArrow ? (
                          <Typography
                            className="previous"
                            onClick={() => next()}
                          >
                            Next Story{" "}
                          </Typography>
                        ) : (
                          ""
                        )}
                      </Box>
                    </Grid>
                  </Grid>
                );
              })}
            </Slider>

            <Box className="get-in-touch wow fadeInUp">
              <Formik
                innerRef={formikRef}
                initialValues={{
                  name: "",
                  email: "",
                  feedback: "",
                }}
                validationSchema={Yup.object().shape({
                  name: Yup.string().required("This field is required."),
                  email: Yup.string()
                    .email("Must be a valid email.")
                    .max(255)
                    .required("This field is required."),
                  feedback: Yup.string().required("This field is required."),
                })}
                onSubmit={(formVal) => {
                  let bodyFormData = new FormData();
                  bodyFormData.append("name", formVal.name);
                  bodyFormData.append("email", formVal.email);
                  bodyFormData.append("feedback", formVal.feedback);
                  bodyFormData.append("rating", 1);
                  bodyFormData.append("type", getinfo);

                  axios
                    .post(URLS.baseURL + URLS.GetinTouch, bodyFormData)
                    .then((res) => {
                      // setApiMessage("success", res?.data?.message);
                      setApiMessage("success", "Feedback sent successfully");
                      if (formikRef.current) {
                        formikRef.current.setFieldValue("name", "");
                        formikRef.current.setFieldValue("email", "");
                        formikRef.current.setFieldValue("feedback", "");
                        formikRef.current.setFieldValue("rating", "");
                      }
                      // setGetInfo({ name: "", email: "", feedback: "" });
                    })
                    .catch((err) => {
                      setApiMessage("success", err?.response?.data?.message);
                    });
                }}
              >
                {({
                  errors,
                  handleBlur,
                  handleChange,
                  handleSubmit,
                  touched,
                  values,
                }) => (
                  <form onSubmit={handleSubmit}>
                    <Box id="help-box" className="help-box">
                      <Typography className="help-head">
                        Need help? Get in touch!
                      </Typography>
                      <Box display="flex" justifyContent="center">
                        <Typography className="help-describe">
                          Our Mission is to empower millions of service
                          professionals by delivering services at-home in a way
                          that has never been experienced before.
                        </Typography>
                      </Box>

                      <TextField
                        error={Boolean(touched.name && errors.name)}
                        helperText={touched.name && errors.name}
                        id="name"
                        className="enter-name"
                        size="small"
                        placeholder="Enter your name"
                        // fullWidth
                        focused
                        name="name"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.name}
                      />
                      <TextField
                        error={Boolean(touched.email && errors.email)}
                        helperText={touched.email && errors.email}
                        id="email"
                        className="enter-email"
                        size="small"
                        placeholder="Enter your email"
                        // fullWidth
                        focused
                        name="email"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.email}
                      />
                      <TextField
                        error={Boolean(touched.feedback && errors.feedback)}
                        helperText={touched.feedback && errors.feedback}
                        id="feedback"
                        className="enter-message"
                        size="small"
                        placeholder="Your message"
                        // fullWidth
                        focused
                        name="feedback"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.feedback}
                      />
                      <Button
                        type="submit"
                        // disableElevation
                        variant="contained"
                        className="send-btn"
                        // fullWidth
                      >
                        Send
                      </Button>
                    </Box>
                  </form>
                )}
              </Formik>
            </Box>
          </Container>
        </Box>
      </Box>
    </Page>
  );
};

export default HomePageView;
