import React, { useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import Page from "../../../component/Page";
import * as Yup from "yup";
import { Formik } from "formik";
import { URLS } from "../../../helpers/url";
import { useDispatch } from "react-redux";
import { authData } from "../../../helpers/stores/actions/auth";
import { saveToStorage } from "../../../helpers/storage";
import { setApiMessage } from "../../../helpers/notification";
import {
  Box,
  RadioGroup,
  FormControlLabel,
  Radio,
  TextField,
  Button,
} from "@mui/material";
import "../auth.scss";
// import { ReactComponent as AuthLogo } from "../../../assets/images/auth-logo.svg";
import { ReactComponent as AuthLogoLogin } from "../../../assets/images/auth-login.svg";
import axios from "axios";

const RegisterPageView = () => {
  const navigate = useNavigate();
  const [isCompany, setIsCompany] = useState("customer");

  const dispatch = useDispatch();

  const ResendOTP = (id, type) => {
    const bodyFormData = new FormData();

    bodyFormData.append("user_id", id);
    bodyFormData.append(
      "type",
      type
      // auth?.user_type === "user" ? "company" : "customer"
    );

    axios
      .post(URLS.baseURL + URLS.resendOTP, bodyFormData)
      .then((res) => {
        setApiMessage("success", "Verification code sent Successfully");
      })
      .catch((err) => {
        setApiMessage("error", err?.response?.data?.message);
      });
  };

  return (
    <Page title="Sign up">
      <Box className="auth-wrap signin" component={"section"}>
        <Box className="col-wrap">
          <Box className="col-left">
            <Box className="form-wrap primary">
              <h1>Create an account</h1>

              <Box className="form-inner">
                <Box className="radio-wrap">
                  <lable>Register as a</lable>
                  <RadioGroup
                    aria-labelledby="demo-radio-buttons-group-label"
                    defaultValue="customer"
                    name="radio-buttons-group"
                    row
                    disableElevation
                    disableRipple
                    disableFocusRipple
                    disableTouchRipple
                  >
                    <FormControlLabel
                      value="customer"
                      control={
                        <Radio
                          checked={isCompany === "customer"}
                          onClick={() => {
                            setIsCompany("customer");
                          }}
                        />
                      }
                      label="Customer"
                    />
                    <FormControlLabel
                      value="company"
                      control={
                        <Radio
                          checked={isCompany === "company"}
                          onClick={() => {
                            setIsCompany("company");
                          }}
                        />
                      }
                      label="Company"
                    />
                  </RadioGroup>
                </Box>

                {/* CUSTOMER */}
                {isCompany === "customer" ? (
                  <Formik
                    initialValues={{
                      name: "",
                      email: "",
                      phone: "",
                      password: "",
                    }}
                    validationSchema={Yup.object().shape({
                      email: Yup.string()
                        .email("Must be a valid email.")
                        .max(255)
                        .required("This field is required."),
                      name: Yup.string().required("This field is required."),
                      phone: Yup.string().required("This field is required."),
                      password: Yup.string()
                        .required("This field is required.")
                        .min(6, "Min 6 characters required."),
                    })}
                    onSubmit={(formVal) => {
                      let bodyFormData = new FormData();

                      bodyFormData.append("email", formVal.email);
                      bodyFormData.append("name", formVal.name);
                      bodyFormData.append("password", formVal.password);
                      bodyFormData.append("contact_name", formVal.name);
                      bodyFormData.append("mobile_number", formVal.phone);
                      bodyFormData.append("type", isCompany);

                      axios
                        .post(URLS.baseURL + URLS.register, bodyFormData)
                        .then((res) => {
                          if (res?.data?.data) {
                            // dispatch(authData(res?.data?.data));
                            // saveToStorage("authData", res?.data?.data);
                            dispatch(
                              authData({
                                ...res?.data?.data,
                                user_type: isCompany,
                              })
                            );
                            saveToStorage("authData", {
                              ...res?.data?.data,
                              user_type: isCompany,
                            });
                            if (res?.data?.data?.user_verified === 0) {
                              let userType = isCompany;
                              // ResendOTP(res?.data?.data?.id, userType);
                              navigate(`/auth/verify?type=${userType}`);
                              // navigate("/auth/verify?type=${userType}");
                            }
                          }

                          // setApiMessage("success", res?.data?.message);
                        })
                        .catch((err) => {
                          setApiMessage(
                            "success",
                            err?.response?.data?.message
                          );
                        });
                    }}
                  >
                    {({
                      errors,
                      handleBlur,
                      handleChange,
                      handleSubmit,
                      touched,
                      values,
                    }) => (
                      <form onSubmit={handleSubmit}>
                        <Box className="input-wrap">
                          <lable>Your Name</lable>
                          <TextField
                            error={Boolean(touched.name && errors.name)}
                            helperText={touched.name && errors.name}
                            placeholder="John Doe"
                            variant="outlined"
                            focused
                            name="name"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.name}
                          />
                        </Box>
                        <Box className="input-wrap">
                          <lable>Email Address</lable>
                          <TextField
                            error={Boolean(touched.email && errors.email)}
                            helperText={touched.email && errors.email}
                            placeholder="Your email"
                            variant="outlined"
                            focused
                            name="email"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.email}
                          />
                        </Box>
                        <Box className="input-wrap">
                          <lable>Mobile Number</lable>
                          <TextField
                            error={Boolean(touched.phone && errors.phone)}
                            helperText={touched.phone && errors.phone}
                            placeholder="Mobile Number"
                            variant="outlined"
                            focused
                            name="phone"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.phone}
                          />
                        </Box>
                        <Box className="input-wrap">
                          <lable>Create Password</lable>
                          <TextField
                            error={Boolean(touched.password && errors.password)}
                            helperText={touched.password && errors.password}
                            type="password"
                            placeholder="Password"
                            variant="outlined"
                            focused
                            name="password"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.password}
                          />
                        </Box>

                        <Button type="submit" className="submit-btn" fullWidth>
                          Sign Up
                        </Button>
                      </form>
                    )}
                  </Formik>
                ) : (
                  <>
                    <Formik
                      initialValues={{
                        name: "",
                        email: "",
                        phone: "",
                        password: "",
                        pname: "",
                      }}
                      validationSchema={Yup.object().shape({
                        name: Yup.string().required("This field is required."),
                        email: Yup.string()
                          .email("Must be a valid email.")
                          .max(255)
                          .required("This field is required."),
                        phone: Yup.string().required("This field is required."),
                        password: Yup.string()
                          .required("This field is required.")
                          .min(6, "Min 6 characters required."),
                        pname: Yup.string().required("This field is required."),
                      })}
                      onSubmit={(formVal) => {
                        let bodyFormData = new FormData();

                        bodyFormData.append("email", formVal.email);
                        bodyFormData.append("name", formVal.name);
                        bodyFormData.append("password", formVal.password);
                        bodyFormData.append("contact_name", formVal.pname);
                        bodyFormData.append("mobile_number", formVal.phone);
                        bodyFormData.append("type", isCompany);

                        axios
                          .post(URLS.baseURL + URLS.register, bodyFormData)
                          .then((res) => {
                            if (res?.data?.data) {
                              // dispatch(authData(res?.data?.data));
                              // saveToStorage("authData", res?.data?.data);
                              dispatch(
                                authData({
                                  ...res?.data?.data,
                                  user_type: isCompany,
                                })
                              );
                              saveToStorage("authData", {
                                ...res?.data?.data,
                                user_type: isCompany,
                              });
                              if (res?.data?.data?.user_verified === 0) {
                                let userType = isCompany;
                                // ResendOTP(res?.data?.data?.id, userType);
                                navigate("/auth/verify");
                              }
                            }

                            // setApiMessage(
                            //   "success",
                            //   "Verification code sent Successfully"
                            // );
                          })
                          .catch((err) => {
                            setApiMessage(
                              "success",
                              err?.response?.data?.message
                            );
                          });
                      }}
                    >
                      {({
                        errors,
                        handleBlur,
                        handleChange,
                        handleSubmit,
                        touched,
                        values,
                      }) => (
                        <form onSubmit={handleSubmit}>
                          <Box className="input-wrap">
                            <lable>Company Name</lable>
                            <TextField
                              error={Boolean(touched.name && errors.name)}
                              helperText={touched.name && errors.name}
                              id="name"
                              placeholder="Quick Provider"
                              variant="outlined"
                              focused
                              name="name"
                              onChange={handleChange}
                              onBlur={handleBlur}
                              value={values.name}
                            />
                          </Box>
                          <Box className="input-wrap">
                            <lable>Email</lable>
                            <TextField
                              error={Boolean(touched.email && errors.email)}
                              helperText={touched.email && errors.email}
                              id="email"
                              placeholder="example@gmail.com"
                              variant="outlined"
                              focused
                              name="email"
                              onChange={handleChange}
                              onBlur={handleBlur}
                              value={values.email}
                            />
                          </Box>
                          <Box className="input-wrap">
                            <lable>Mobile Number</lable>
                            <TextField
                              error={Boolean(touched.phone && errors.phone)}
                              helperText={touched.phone && errors.phone}
                              id="phonnumber"
                              placeholder="Mobile Number"
                              variant="outlined"
                              focused
                              name="phone"
                              onChange={handleChange}
                              onBlur={handleBlur}
                              value={values.phone}
                            />
                          </Box>
                          <Box className="input-wrap">
                            <lable>Create Password</lable>
                            <TextField
                              error={Boolean(
                                touched.password && errors.password
                              )}
                              helperText={touched.password && errors.password}
                              id="password"
                              type="password"
                              placeholder="Password"
                              variant="outlined"
                              focused
                              name="password"
                              onChange={handleChange}
                              onBlur={handleBlur}
                              value={values.password}
                            />
                          </Box>
                          <Box className="input-wrap">
                            <lable>Contact Person Name</lable>
                            <TextField
                              error={Boolean(touched.pname && errors.pname)}
                              helperText={touched.pname && errors.pname}
                              id="pname"
                              type="pname"
                              placeholder="Name"
                              variant="outlined"
                              focused
                              name="pname"
                              onChange={handleChange}
                              onBlur={handleBlur}
                              value={values.pname}
                            />
                          </Box>

                          <Button
                            type="submit"
                            className="submit-btn"
                            fullWidth
                          >
                            Sign Up
                          </Button>
                        </form>
                      )}
                    </Formik>
                  </>
                )}
                <p className="account-text">
                  Already have account?
                  <b>
                    <NavLink to="/auth/sign-in" className="sign-in">
                      Log in
                    </NavLink>
                  </b>
                </p>
              </Box>
            </Box>
          </Box>
          <Box className="col-right">
            <Box className="content">
              <AuthLogoLogin />
              <h2>
                Helping you find <br />
                services that you
                <br /> need in seconds.
              </h2>
            </Box>
          </Box>
        </Box>
      </Box>
    </Page>
  );
};

export default RegisterPageView;
