import React from "react";
import { ToastContainer } from "react-toastify";
import { Outlet } from "react-router-dom";
import TopBar from "./TopBar";
import Footer from "../ClientLayout/Footer";

const UserLayout = () => {
  return (
    <div>
      <ToastContainer />
      <TopBar />
      <Outlet />
      <Footer />
    </div>
  );
};

export default UserLayout;
