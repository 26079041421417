import React, { useEffect, useState } from "react";
import Page from "../../component/Page";
import { IconButton } from "@material-ui/core";
import {
  Container,
  Box,
  Typography,
  Grid,
  Avatar,
  Input,
  Button,
} from "@mui/material";
import Customerprofile from "../../assets/images/customer-profile.png";
import "./Myprofile.scss";
import { Formik } from "formik";
import axios from "axios";
import { authData } from "../../helpers/stores/actions/auth";
import { URLS } from "../../helpers/url";
import { setApiMessage } from "../../helpers/notification";
import { fetchFromStorage } from "../../helpers/storage";
import EditIcon from "@mui/icons-material/Edit";

const MyProfilePageView = () => {
  const [storeInfo, setStoreInfo] = useState("");

  const [imgUpload, setImgUpload] = useState("");

  const [userProfile, setUserProfile] = useState("");

  const storage = fetchFromStorage("authData");

  const uploadProfileImage = (e) => {
    e.persist();

    setImgUpload(URL.createObjectURL(e.target.files[0]));
    // console.log(e);

    // var bodyFormData = new FormData();

    // bodyFormData.append("image", e.target.files[0]);
    let bodyFormData = new FormData();

    bodyFormData.append("type", "customer");

    bodyFormData.append("image", e.target.files[0]);
    bodyFormData.append("user_id", userProfile?.id);

    axios
      .post(URLS.baseURL + URLS.uploadProfileImage, bodyFormData)
      .then((res) => {
        setApiMessage("success", res?.data?.message);
        // console.log(res.data);
      })
      .catch((err) => {
        setApiMessage("success", err?.response?.data?.message);
      });
  };

  // console.log(storage);

  const GetProfile = (updates) => {
    let bodyFormData = new FormData();

    // bodyFormData.append("company_id", storage?.id);
    bodyFormData.append("user_id", storage?.id);

    axios
      .post(URLS.baseURL + URLS.customer_profile, bodyFormData, {
        headers: { "Content-Type": "multipart/form-data" },
      })
      .then((res) => {
        setImgUpload(res?.data?.data?.user_image);
        if (!updates) {
          setApiMessage("success", res?.data?.message);
        }
        // console.log("janki", res?.data?.data);

        setUserProfile(res?.data?.data);
      })
      .catch((err) => {
        setApiMessage("success", err?.response?.data?.message);
      });
  };
  // console.log("j", userProfile);

  // console.log(JSON.parse(localStorage.getItem("authData")));
  useEffect(() => {
    GetProfile(true);
    setStoreInfo(JSON.parse(localStorage.getItem("authData")));
  }, []);

  // console.log();

  return (
    <Page title="My Profile | QXP">
      <Container maxWidth="lg">
        <Box className="customer-container">
          <Box className="Myprofile-wrap">
            {/* WELCOME HEADING */}
            <Typography variant="h1">Welcome to quick provider</Typography>
            <Box display={"flex"} justifyContent="center">
              {/*  */}

              {/* <Formik>
                initialValues=
                {{
                  name: "",
                  mail: "",
                  phone: "",
                }}

              </Formik> */}

              <Grid container spacing={4} className="customer-detail-container">
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={6}
                  lg={6}
                  className="customer-detail"
                >
                  <Box className="display-flex">
                    {/* {/* <input
                      accept="image/*"
                      id="upload-profile-image"
                      type="file"
                      onChange={uploadProfileImage}
                    ></input> */}
                    {/* <Avatar
                      className="customer-pic"
                      sx={{ width: 100, height: 100 }}
                      src={Customerprofile}
                      accept="image/*"
                      id="upload-profile-image"
                      type="file"
                      onChange={uploadProfileImage}
                    /> */}

                    <input
                      accept="image/*"
                      id="icon-33-file"
                      type="file"
                      onChange={uploadProfileImage}
                      style={{ display: "none" }}
                    />
                    <label htmlFor="icon-33-file">
                      <IconButton
                        className="customer-pic"
                        sx={{ width: 100, height: 100 }}
                        color="primary"
                        aria-label="upload picture"
                        component="span"
                      >
                        <Avatar src={imgUpload}></Avatar>
                        <EditIcon />
                      </IconButton>
                    </label>

                    <Box>
                      <Typography className="customer-name" name="name">
                        {storeInfo.name}
                      </Typography>
                      <Typography className="customer-mail" name="email">
                        {storeInfo.email}
                      </Typography>
                      <Typography className="customer-number" name="phone">
                        {storeInfo.mobile}
                      </Typography>
                    </Box>
                  </Box>
                </Grid>

                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={6}
                  lg={6}
                  className="customer-address"
                >
                  <Box className="box-border">
                    <Typography className="Add-title">My Address</Typography>

                    <Typography className="address" name="postal_address">
                      {userProfile?.postal_address}
                    </Typography>
                  </Box>
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={6}
                  lg={6}
                  className="customer-detail-container"
                >
                  <Box className="company-contact">
                    <Typography className="contact">
                      Companies Contacted
                    </Typography>
                    <Typography className="number" name="average_ratings">
                      {userProfile?.total_companies}
                    </Typography>
                  </Box>
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={6}
                  lg={6}
                  className="customer-detail-container"
                >
                  <Box className="company-contact">
                    <Typography className="contact">
                      Service Requests
                    </Typography>
                    <Typography className="number">
                      {userProfile?.total_requests}
                    </Typography>
                  </Box>
                </Grid>

                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={6}
                  lg={6}
                  className="customer-detail-container"
                ></Grid>
              </Grid>
            </Box>
          </Box>
        </Box>
      </Container>
    </Page>
  );
};

export default MyProfilePageView;
